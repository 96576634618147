body {
    margin: 0;
    padding: 0;
    font-family: sans-serif;
    background-color: #F5F5F5;
}

.bold {
    font-weight: bold;
}

.m10 {
    margin: 10px;
}

.mt10 {
    margin-top: 10px;
}

.mb10 {
    margin-bottom: 10px;
}

.ml10 {
    margin-left: 10px;
}

.mr10 {
    margin-right: 10px;
}

.center {
    text-align: center;
}

.modal {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.paper {
    width: 80vw;
    padding: 32px;
    max-width: 500px;
    background-color: #fff;
}

.h100 {
    height: 100vh;
}

.h80 {
    height: 80%;
}

.w100 {
    width: 100%;
}

.noUnderline {
    text-decoration: none;
}

.p10 {
    padding: 10px;
}

.p5 {
    padding: 5px;
}

.pt10 {
    padding-top: 10px
}

.pb10 {
    padding-bottom: 10px;
}

.pr10 {
    padding-right: 10px;
}

.pl10 {
    padding-left: 10px;
}

.larger {
    font-size: larger;
}

.flex-left-right {
    display: flex;
    justify-content: space-between;
}

.border-round {
    border: 10px solid;
    border-radius: 20px;
}

.win-container {
    width: 90vw;
    margin-top: 30px;
    max-width: 450px;
    background-color: #fff;
}

.v-centering {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.h-centering {
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.centering {
    display: flex;
    justify-content: center;
    align-items: center;
}

.btn {
    border-bottom: solid 3px;
    border-radius: 5px !important;
    text-decoration: none !important;
    color: #fff !important;
}

.btn:active {
    transform: translateY(3px) !important;
    border-bottom: none !important;
}

.btn:disabled {
    transform: translateY(3px) !important;
    background-color: lightgray !important;
    border-bottom: none !important;
}

.btnSize {
    width: 100px !important;
    height: 35px !important;
}

.btnPrimary {
    color: #fff !important;
    background-color: rgb(138, 199, 122) !important;
    border-bottom: solid 3px #79b369 !important;
    text-decoration: none !important;
    border-radius: 5px !important;
    box-shadow: 0 5px 0 0 rgba(121, 179, 105, 0.004) !important;
    white-space: nowrap;
}

.btnPrimary:active {
    transform: translateY(3px) !important;
    border-bottom: none !important;
}

.btnPrimary:disabled {
    /*transform: translateY(3px) !important;*/
    background-color: lightgray !important;
    border-bottom: solid 3px darkgray !important;
}

.btnSecondary {
    color: #fff !important;
    background-color: #afafaf !important;
    border-bottom: solid 3px #9a9a9a !important;
    text-decoration: none !important;
    border-radius: 5px !important;
    box-shadow: 0 5px 0 0 rgba(121, 179, 105, 0.004) !important;
}

.btnSecondary:active {
    transform: translateY(3px) !important;
    border-bottom: none !important;
}

.btnSecondary:disabled {
    /*transform: translateY(3px) !important;*/
    background-color: lightgray !important;
    border-bottom: solid 3px darkgray !important;
}

.account_avatar_div img {
    border-radius: 50px;
    background-color: white;
    border: solid black 3px;
}

.error {
    color: red !important;
}

.bgWhite {
    background-color: white !important;
}

.questumBackgroundColor {
    background-color: orangered !important;
    border: 1px solid orangered !important;
}
#calendars .fc-time-grid-container {
    height: auto !important;
}

